import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import HomeLayout from "layouts/HomeLayout";

const routes = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/auth/login" />,
  },
  {
    path: "/maintenance",
    exact: true,
    component: lazy(() => import("pages/NoConnection")),
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("pages/Login")),
      },
      {
        path: "/auth/verify",
        exact: true,
        component: lazy(() => import("pages/LoginCode")),
      },
      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
  {
    path: "/app",
    component: HomeLayout,
    routes: [
      {
        path: "/app/clients",
        exact: true,
        component: lazy(() => import("pages/Clients")),
      },
      {
        path: "/app/clients/create",
        exact: true,
        component: lazy(() => import("pages/Client")),
      },
      {
        path: "/app/clients/:id",
        exact: true,
        component: lazy(() => import("pages/Client")),
      },
      {
        path: "/app/contributors",
        exact: true,
        component: lazy(() => import("pages/Contributors")),
      },
      {
        path: "/app/documents",
        exact: true,
        component: lazy(() => import("pages/Documents")),
      },
      {
        path: "/app/tags",
        exact: true,
        component: lazy(() => import("pages/Tags")),
      },
      {
        path: "/app/profile",
        exact: true,
        component: lazy(() => import("pages/ProfileSettings")),
      },
      {
        path: "/app/permissions",
        exact: true,
        component: lazy(() => import("pages/Permissions")),
      },
      {
        path: "/app/maintenance",
        exact: true,
        component: lazy(() => import("pages/Maintenance")),
      },
      {
        component: () => <Redirect to="/app/documents" />,
      },
    ],
  },
  {
    path: "*",
    component: () => <Redirect to="/auth/login" />,
  },
];

export default routes;
